// UserContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Adjust the import paths as needed
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'; //getDoc used to be imported too but isn't used now.

export type UserData = {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  paidSubscription: boolean;
  hasTakenSAT: boolean;
  hasTakenACT: boolean;
  previousSATScores: { math: string; readingWriting: string };
  previousACTScores: { math: string; reading: string; writing: string; science: string };
  hasUpcomingTest: boolean;
  testDate: string;
  targetScores: { math: string; readingWriting: string };
  colleges: string;
  intendedMajor: string;
  strengthsAndWeaknesses: string;
  motivation: string;
  feedbackStyle: string;
  areasForImprovement: string[];
  personalInterests: string;
  grandTotalQuestions: number;
  completedSATTests: number;
  totalDaysPracticed: number;
  numberOfDaysInAStreak: number;
  // ... include other fields as needed
  skills: {
    [skillName: string]: {
      currentTestQuestionsAttempted: number;
      currentTestQuestionsCorrect: number;
      currentTestEasyQuestionsAttempted: number;
      currentTestEasyQuestionsCorrect: number;
      easyQuestionsIndex: number;
      currentTestMediumQuestionsAttempted: number;
      currentTestMediumQuestionsCorrect: number;
      mediumQuestionsIndex: number;
      currentTestHardQuestionsAttempted: number;
      currentTestHardQuestionsCorrect: number;
      hardQuestionsIndex: number;
      overallQuestionsAttempted: number;
      overallQuestionsCorrect: number;
    };
  };
};

type UserContextType = {
  user: UserData | null;
  loading: boolean;
  initialLoading: boolean;
  error: Error | null;
  updateUserData: (data: Partial<UserData>) => Promise<void>;
};

export const UserContext = createContext<UserContextType>({
  user: null,
  loading: true,
  initialLoading: true,
  error: null,
  updateUserData: async () => {
    throw new Error("updateUserData function must be provided by the UserProvider");
  },
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true); // NEW: Tracks initial loading


  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => { //onAuthStateChanged is a listener; unsubscribeAuth unmounts it
      if (firebaseUser) {
        const uid = firebaseUser.uid;
        const userDocRef = doc(db, 'users', uid);

        const unsubscribeUserDoc = onSnapshot(
          userDocRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const userData = { uid, ...docSnap.data() } as UserData;
              setUser(userData);
              setLoading(false);
            } else {
              setError(new Error('User data not found'));
              setLoading(false);
            }
            setInitialLoading(false); // Stop initial loading regardless of the result
          },
          (error) => {
            setError(error);
            setLoading(false);
            setInitialLoading(false); // Stop initial loading if an error occurs
          }
        );

        return () => {
          unsubscribeUserDoc();
        };
      } else {
        setUser(null);
        setLoading(false);
        setInitialLoading(false); // Stop initial loading if no user is logged in
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const updateUserData = async (data: Partial<UserData>) => {
    if (!user) return;
    const userDocRef = doc(db, 'users', user.uid);
    try {
      await updateDoc(userDocRef, data);

      // Manually update the user state to reflect changes immediately - do I need this?
    setUser((prevUser) => (prevUser ? { ...prevUser, ...data } : prevUser));

    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  //Prior to 8 am on 11/15, instead of {initialLoading...}, there was just {children}
  return (
    <UserContext.Provider value={{ user, loading, initialLoading, error, updateUserData }}>
      {children} {/* Render loading screen during initial load */}
    </UserContext.Provider>
  );
};
