import React, {useState, useEffect} from 'react';
//import ChatComponent from './ChatComponent';
import { useNavigate } from 'react-router-dom';
import { getAuth, setPersistence, browserLocalPersistence, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // importsinstance of db from firebase.ts. 
import CreateProfile, { FormData as ProfileFormData} from './CreateProfile';
import './HomePage.css';
//Not using auth now because I recreate it here below, but I should use the same instance from firebase.ts - test later.

declare global {
    namespace JSX {
      interface IntrinsicElements {
        'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
    }
  }
  
  //This is used to process payment natively in the app. 
  //It's at the bottom of the HTML in a {emailsubmitted && signupComplete && <StripeButton>} conditional display format.
const StripeButton: React.FC = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/buy-button.js';
      script.async = true;

      // Listen for the script to fully load
      script.onload = () => {console.log('Stripe Buy Button script loaded.');};
      script.onerror = () => {console.error('Error loading Stripe Buy Button script.');};
  
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, []);

    /*SUPER IMPORTANT
        TEST BUTTON for Stripe: no payment will be processed because it's test mode - Note the "test":
        buy-button-id="buy_btn_1PspjKP4YfvUie3H8KOSLqkn"
        publishable-key="pk_test_51PrdspP4YfvUie3HkkwEVjXTbr0Tfo2Pr6B52xVP5j786RupOMQoGGyfusECMsXm30dPKBhvG8MJt98UpStCM9Is00WN98bRgB"

        REAL PAYMENTS BUTTON for Stripe - Note the "live":
        buy-button-id="buy_btn_1PsoK2P4YfvUie3HlfLU4XtV"
        publishable-key="pk_live_51PrdspP4YfvUie3HPi7JA9D2euTaYCLm9dWwyNcGAAixLwekoYJ5w3m5lf0CJwkFuhiaI8TcfDkEerv1POxMsiWp00EqUKBjEu"
    */
    
    return (
      <stripe-buy-button
        buy-button-id="buy_btn_1PspjKP4YfvUie3H8KOSLqkn"
        publishable-key="pk_test_51PrdspP4YfvUie3HkkwEVjXTbr0Tfo2Pr6B52xVP5j786RupOMQoGGyfusECMsXm30dPKBhvG8MJt98UpStCM9Is00WN98bRgB"
      >
      </stripe-buy-button>
    );
  };
  

const HomePage: React.FC = () => {
  const [showProfile, setShowProfile] = useState(true); //displays CreateProfile component
  //const [profileData, setProfileData] = useState<ProfileFormData | null>(null);
  //const [email, setEmail] = useState('');
  //const [createProfileSubmitted, setCreateProfileSubmitted] = useState(false); //toggles CreateProfile component, 
  //const [emailSubmitted, setEmailSubmitted] = useState(false); //toggles whether email input is present or disappears, right?
  //const [signupComplete, setSignupComplete] = useState(false); //if email submitted and signupComplete, toggles Stripe buy button
  //const [showSuccessMessage, setShowSuccessMessage] = useState(false); //after signup complete, show success message to pay on Stripe
  //const [password, setPassword] = useState('');
  //const [firstName, setFirstName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  

  const handleRegistrationComplete = () => {
    console.log('Registration complete, redirecting to SAT practice');
    navigate('/sat');  // Or wherever you want to redirect
  };
  
  /* Email validation
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //validates that there's 1+characters + "@" + 1+characters + "." + 1+ chars
    return emailRegex.test(email); //returns true if matches validation, false otherwise.
  };

  // Handle email submission
  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setError(null); //sets that there is no Error
      setEmailSubmitted(true); // Trigger the animation and show the next form inputs
    } else {
      setError('Please enter a valid email address.');
    }
  };

  // Handle the full registration form submission
  const handleRegistrationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }
    if (!firstName.trim()) { //.trim takes off spaces after end of name
      setError('Please enter your first name.');
      return;
    }
    
    setError(null); //resets setError once password and firstName pass validation...is this necessary?

    try {
      // Simulate successful registration
      const auth = getAuth(); // Initialize Firebase Auth
      await setPersistence(auth, browserLocalPersistence); // Firebase's way of maintaining login even when user goes to another website
      const userCredential = await createUserWithEmailAndPassword(auth, email, password); // Create user in Firebase for authentication
      const user = userCredential.user; //Create user in Firestore, the database
      console.log('New user now registered: ', userCredential.user);

      //New on 9/16/2024: Creating a Firestore document for the new user
      const userDocRef = doc(db, 'users', user.uid); //db is imported from "firebase.ts" when it is initialized.
      await setDoc(userDocRef, {
        firstName,
        lastName: '', // You can add last name field later if needed
        email: user.email,
        paidSubscription: false, // Default to false, will update after Stripe payment
        // Spread profile data if it exists
        ...(profileData || {}), //added at 11 am on 11/20/2024
        
        grandTotalQuestions: 0,
        completedSATTests: 0,
        totalDaysPracticed: 0,
        numberOfDaysInAStreak: 0,
        testScores: {
          test1: { mathScore: 0, readingWritingScore: 0 },
          test2: { mathScore: 0, readingWritingScore: 0 },
          // Additional test scores up to test10 can be added
        },
        currentTestProgress: {
          mathQuestionsAttempted: 0,
          mathCorrectAnswers: 0,
          readingWritingQuestionsAttempted: 0,
          readingWritingCorrectAnswers: 0
        },
        skills: {
          algebra: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          circles: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          descriptive_phrases: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          exponential_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          exponential_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          function_of_sentence: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          geometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          inferences: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          interpreting_graphs: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          linear_inequality_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          main_idea: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          percent: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          phrasing: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          polynomial_expressions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          probability: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          pronouns: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          punctuation: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          quadratic_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          quadratic_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          referencing_data: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          statistics: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          supporting_claims: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          synthesizing_notes: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          systems_of_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          tenses: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          transition_words: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          trigonometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          two_passages: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          unit_conversions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
          word_choice: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 }
        }
      });

      //setSignupComplete(true); // necessary to pull up the Stripe payment button
      setShowSuccessMessage(true); //pull up success message to give directions for payment

      //alert('Registration successful! Redirecting to complete payment, then you can get started!'); //alert box to user
      // Redirect to the Stripe payment page

      //STRIPE ALLOWS PREFILLED EMAILS, WHICH I CAN PROBABLY SUBMIT ON THE NEXT LINE
      //See this link to figure out how to configure: https://docs.stripe.com/payment-links/customize#customize-checkout-with-url-parameters
      //window.location.href = 'https://buy.stripe.com/28odU7b1M1seaLmaEE'; //redirects current page to stripe
      //window.open('https://buy.stripe.com/28odU7b1M1seaLmaEE', '_blank'); //opens Stripe in a new window but is blocked by pop-up blocker
      //***NEED TO ADJUST THIS DEPENDING ON HOW STRIPE CAN REDIRECT AFTER COMPLETING PAYMENT

    } catch (error) {
      setError('Registration failed. Please try again.');
    }
  }; */

  return (
    <main>
      <section className="hero-home-page">
        <div className="hero-content">
          <div className="hero-content-text">
            <h2>Want to Achieve Your College Dreams?</h2> 
            <h3>Let's begin your journey of excellence with SAT prep below!</h3>
          </div>

          {showProfile ? (
            <CreateProfile onRegistrationComplete={handleRegistrationComplete}/>
          ) : null}

          {/*Step 1: This is the profile creation form, inviting students to share their goals}
          {!emailSubmitted && !showSuccessMessage && !createProfileSubmitted ? (
            <CreateProfile onSubmit={(formData) => {
              setProfileData(formData);
              setCreateProfileSubmitted(true);
            }}/>
          ) : null}
          
          {/*Step 2: Email submission form, inviting students to submit their email}
          {createProfileSubmitted && !emailSubmitted && !showSuccessMessage ? (
              <form id="email-signup-form" onSubmit={handleEmailSubmit}>
              <input
                  type="email"
                  className="form-input"
                  placeholder="Type your email here..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
              <button className="cta-button" type="submit">Sign Up Now!</button> {/*or "Sign Up Now! - which is better?" }

              {error && <p className="error-message">{error}</p>}
              </form>
          ) : null}

          {/*Step 3: Full signup form, inviting students to create a password and submit their first name}
          {emailSubmitted && !showSuccessMessage ? (
              <form id="full-signup-form" onSubmit={handleRegistrationSubmit} className="slide-up">
              <input
                  type="password"
                  className="form-input"
                  placeholder="Create a password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
              <input
                  type="text"
                  className="form-input"
                  placeholder="First Name..."
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
              />
              
              <button className="cta-button" type="submit">Finish Sign Up</button>
              {error && <p className="error-message">{error}</p>}
              </form>
          ) : null}
          
          {/*Step 4: Success message, thanking the user for creating an account and prompting them to submit payment or click the button to diretly go to SAT}
          {showSuccessMessage ? (
              <div className="success-message">
                <h3 color='ffffff'>Great! Ready to get started? Click the link below</h3>
                <button className="cta-button" onClick={redirectToSAT}>Start Practicing</button>
                {/*<h3 color='ffffff'>The last step before beginning your practice is submitting payment with the button below!</h3>}             </div>
          ) : null}
        </div>

        {/*Step 5: Add Stripe payment button, allowing users to pay*/}
        {/*{signupComplete ? (
          <div className = "stripe-button-container">
            <StripeButton />
          </div>
        ) : ( null ) }*/}
      </div>
      </section>
      <h1>Guiding You to Success on the SAT</h1>
      <div className="SAT-content">
          <div className="SAT-text">
            <p>With the all new Digital SAT test revision in 2024, most SAT prep services haven't updated their curriculum 
              to include the new question types - so we're here to help, making SAT prep easy and effective to get into 
              your dream schools with scholarships!</p>
              <br></br>
            <p>We break down the test by each question type so you can drill what is most important to you and get instant feedback:</p>

            <ul>
              <li>Review essential math knowledge and strategies to ace the math section</li>
              <li>Learn the most common grammar rules to reduce mistakes</li>
              <li>Increase your reading speed and ability to understand complex passages</li>
              <li>Develop learning habits that will help you in college and beyond!</li>
            </ul>
          </div>

          <div className="SAT-image">
              <img src="/images/thrilled-student.JPG" alt="SAT preparation"/>
          </div>
      </div>
  </main>
  );
};

export default HomePage;
// Save the first name to localStorage before redirecting to Stripe
        //localStorage.setItem('firstName', firstName); //not necessary if users pay on my site

/*
<section className="SAT-overview">
                <p>Still in development: The purpose of this section is to describe at a high level how our program will help students and be different than other SAT Prep programs.</p>
            </section>

***************************************
Make these into clickable buttons? There could be all kinds of prompts like this!!!
Welcome to Your SAT Practice Partner replaced by 'Achieve Your College Dreams'

***ADD THIS BACK INTO THE BUTTON AFTER DEBUGGING: onClick={redirectToSignUp}


<section className="features">
                <div className="container">
                    <h2>Features</h2>
                    <ChatComponent />
                    <div className="features-grid">
                        <div className="feature">
                            <h3>Prompt 1</h3>
                            <p>Hi ChatGPT, I need to practice solving systems of equations. Could you please create two problems for each method (graphing, substitution, elimination) for me to solve? After I solve them, I'll input my answers, and I'd like you to tell me if I'm correct or not. If I'm wrong, please explain why and give me some easier problems to build my confidence before moving on to harder problems. If I get them right, please gradually increase the difficulty and make them more interesting. Also, remind me that I can ask for a hint if I need help but don't give me the answer. Finally, after a few rounds, could you create some relatable real-life word problems that incorporate the math skills I'm working on? Thanks!</p>
                        </div>
                        
                    </div>
                </div>
            </section>

            These were within that features grid above:
<div className="features-grid">
                        <div className="feature">
                            <h3>Prompt 1</h3>
                            <p>Hi ChatGPT, I need to practice solving systems of equations. Could you please create two problems for each method (graphing, substitution, elimination) for me to solve? After I solve them, I'll input my answers, and I'd like you to tell me if I'm correct or not. If I'm wrong, please explain why and give me some easier problems to build my confidence before moving on to harder problems. If I get them right, please gradually increase the difficulty and make them more interesting. Also, remind me that I can ask for a hint if I need help but don't give me the answer. Finally, after a few rounds, could you create some relatable real-life word problems that incorporate the math skills I'm working on? Thanks!</p>
                        </div>
                        <div className="feature">
                            <h3>Prompt 2</h3>
                            <p>I want to read faster. Write me a 300 word SAT-level essay then create 3 reading comprehension problems for me.</p>
                        </div>
                        <div className="feature">
                            <h3>Prompt 3</h3>
                            <p>I feel like I miss a lot of punctuation problems. Can you review the 5 most important punctuation or grammar concepts and give me 3 practice qeustions on each which are increasingly challenging?</p>
                        </div>
                    </div>




/*
Old code for email authorization that didn't work:

    useEffect below is for this form and button:
     <form id="email-signup-form">
        <input type="email" className="email-input" id="email" placeholder="Type your email..." required/>
        <button className="cta-button" type="submit">Sign Up Today!</button>
    </form>


    useEffect(() => {
        const form = document.getElementById('email-signup-form');
        form?.addEventListener('submit', async (e) => { //Activates when the button below is clicked. Use the same form at bottom of Home
            e.preventDefault();
            const emailInput = document.getElementById('email') as HTMLInputElement | null;
            if (emailInput){
                const email = emailInput.value;
                console.log(`Email provided is ${email}`);
        
                // Makes an API call to backend to handle email submission
                const response = await fetch('/api/auth/send-confirmation', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json',},
                    body: JSON.stringify({ email }),    
                });

                if (!response.ok) {
                    const text = await response.text();
                    console.error('Error response:', text);
                    throw new Error(`HTTP error! status: ${response.status}`);
                } //could simplify the next part by putting the "if (response.ok)" part to an else after this first if
        
                const result = await response.json();
                console.log(`Result after sending email address: ${result}`)
        
                if (response.ok) {
                    alert('Confirmation email sent! Please check your inbox.');
                } else {
                    alert('There was an error. Please try again.');
                }
            }
            else{
                console.error("Email element was not found.");
            }
        });
    }, []);
    */
