import React, { useState, useContext } from 'react';
import { CheckCircle2, BookOpen, Trophy, Calendar, User } from 'lucide-react'; //AlertCircle,  
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
//import { Alert, AlertDescription } from "./ui/alert";
import { UserContext, type UserData } from './UserContext';
import { getAuth, setPersistence, browserLocalPersistence, createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // importsinstance of db from firebase.ts. 

export type FormData = {
    email: string;
    password: string;
    firstName: string;
    
    hasTakenSAT: boolean;
    hasTakenACT: boolean;
    previousSATScores: { math: string; readingWriting: string };
    previousACTScores: { math: string; reading: string; writing: string; science: string };
    hasUpcomingTest: boolean; 
    testDate: string; 
    targetScores: { math: string; readingWriting: string };
    colleges: string;
    intendedMajor: string;
    motivation: string;
    strengthsAndWeaknesses: string;
    feedbackStyle: string;
    areasForImprovement: string[];
    personalInterests: string;
  };

interface FormErrors {
  satMath?: string;
  satReading?: string;
  testDate?: string;
  targetMath?: string;
  targetReading?: string;
  colleges?: string;
  motivation?: string;
  feedbackStyle?: string;
  areasForImprovement?: string;
  personalInterests?: string;
  email?: string;
  password?: string;
  firstName?: string;
  submit?: string;
  [key: string]: string | undefined;
}

const improvementAreas = [
  { id: 'math', label: 'Math', icon: '📊' },
  { id: 'reading', label: 'Reading', icon: '📚' },
  { id: 'writing', label: 'Writing Skills', icon: '✍️' },
  { id: 'timeManagement', label: 'Time Management', icon: '⏰' },
  { id: 'testAnxiety', label: 'Test Anxiety', icon: '😰' }
] as const;

type ImprovementArea = typeof improvementAreas[number]['id'];

// Add interface for props so this can be called in HomePage.tsx
interface CreateProfileProps {
  onRegistrationComplete: () => void;  // Simplified callback
}

// Update component definition with type
const CreateProfile: React.FC<CreateProfileProps> = ({ onRegistrationComplete }) => {
  const { updateUserData } = useContext(UserContext);

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    firstName: '',
    hasTakenSAT: false,
    hasTakenACT: false,
    previousSATScores: { math: '', readingWriting: '' },
    previousACTScores: { math: '', reading: '', writing: '', science: '' },
    hasUpcomingTest: false,
    testDate: '',
    targetScores: { math: '', readingWriting: '' },
    colleges: '',
    intendedMajor: '',
    strengthsAndWeaknesses: '',
    motivation: '',
    feedbackStyle: '',
    areasForImprovement: [] as ImprovementArea[], //This should be removed as it's not used
    personalInterests: ''//This should be removed as it's not used
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [step, setStep] = useState(1);
  const totalSteps = 5;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateStep = (currentStep: number): boolean => {
    const newErrors: FormErrors = {};

    switch (currentStep) {
      case 1:
        if (formData.hasTakenSAT) {
          if (formData.previousSATScores.math && !validateSATScore(formData.previousSATScores.math)) {
            newErrors.satMath = 'SAT Math score must be between 200 and 800';
          }
          if (formData.previousSATScores.readingWriting && !validateSATScore(formData.previousSATScores.readingWriting)) {
            newErrors.satReading = 'SAT Reading score must be between 200 and 800';
          }
        }
        if (formData.hasTakenACT) {
          Object.entries(formData.previousACTScores).forEach(([section, score]) => {
            if (score && !validateACTScore(score)) {
              newErrors[`act${section}`] = 'ACT score must be between 1 and 36';
            }
          });
        }
        if (formData.hasUpcomingTest && !formData.testDate) {
          newErrors.testDate = 'Please enter your upcoming test date';
        }
        break;
      case 2:
        if (!validateSATScore(formData.targetScores.math)) {
          newErrors.targetMath = 'Target Math score must be between 200 and 800';
        }
        if (!validateSATScore(formData.targetScores.readingWriting)) {
          newErrors.targetReading = 'Target Reading score must be between 200 and 800';
        }
        if (!formData.colleges.trim()) {
          newErrors.colleges = 'Please enter at least one college';
        }
        break;
      case 3:
        if (!formData.motivation.trim()) {
          newErrors.motivation = 'Please share what motivates you';
        }
        break;
      case 4:
        if (!formData.feedbackStyle) {
          newErrors.feedbackStyle = 'Please select your preferred feedback style';
        }
        break;
      case 5:
        // Email validation
      if (!formData.email.trim()) {
        newErrors.email = 'Email is required';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = 'Please enter a valid email address';
      }
      // Password validation
      if (!formData.password) {
        newErrors.password = 'Password is required';
      } else if (formData.password.length < 6) {
        newErrors.password = 'Password must be at least 6 characters';
      }
      // First name validation
      if (!formData.firstName.trim()) {
        newErrors.firstName = 'First name is required';
      }
      break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateSATScore = (score: string): boolean => {
    const numScore = parseInt(score);
    return !isNaN(numScore) && numScore >= 200 && numScore <= 800;
  };

  const validateACTScore = (score: string): boolean => {
    const numScore = parseInt(score);
    return !isNaN(numScore) && numScore >= 1 && numScore <= 36;
  };

  const renderStep1 = () => (
    <div className="step-container">
      <Card>
        <CardContent>
          <label className="input-label">Have you taken the SAT or ACT before? If so, click the buttons and enter your scores.</label>
          <div className="test-button-group">
            <button
              onClick={() => setFormData(prev => ({ ...prev, hasTakenSAT: !prev.hasTakenSAT }))}
              className={`test-toggle-button ${formData.hasTakenSAT ? 'active' : ''}`}
              type="button"
            >
              SAT
            </button>
            <button
              onClick={() => setFormData(prev => ({ ...prev, hasTakenACT: !prev.hasTakenACT }))}
              className={`test-toggle-button ${formData.hasTakenACT ? 'active' : ''}`}
              type="button"
            >
              ACT
            </button>
          </div>

          {formData.hasTakenSAT && (
            <div className="score-input-container">
              <div className="score-input-group">
                <label className="score-label">SAT Math</label>
                <input type="number" value={formData.previousSATScores.math}
                  onChange={(e) => setFormData(prev => ({
                    ...prev, previousSATScores: { ...prev.previousSATScores, math: e.target.value }
                  }))}
                  className="profile-input" placeholder="200-800"
                />
                {errors.satMath && (
                  <p className="error-text">{errors.satMath}</p>
                )}
              </div>

              <div className="score-input-group">
                <label className="score-label">SAT Reading and Writing</label>
                <input type="number" value={formData.previousSATScores.readingWriting}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    previousSATScores: { ...prev.previousSATScores, readingWriting: e.target.value }
                  }))}
                  className="profile-input" placeholder="200-800"
                />
                {errors.satReading && (
                  <p className="error-text">{errors.satReading}</p>
                )}
              </div>
            </div>
          )}

          {formData.hasTakenACT && (
            <div className="score-input-container">
              <div className="score-input-group">
                <label className="score-label">ACT Math</label>
                <input type="number" value={formData.previousACTScores.math}
                  onChange={(e) => setFormData(prev => ({
                    ...prev, previousACTScores: { ...prev.previousACTScores, math: e.target.value }
                  }))}
                  className="profile-input" placeholder="1-36"
                />
                {errors.actMath && (
                  <p className="error-text">{errors.actMath}</p>
                )}
              </div>

              <div className="score-input-group">
                <label className="score-label">ACT Reading</label>
                <input type="number" value={formData.previousACTScores.reading}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    previousACTScores: { ...prev.previousACTScores, reading: e.target.value }
                  }))}
                  className="profile-input" placeholder="1-36"
                />
                {errors.actReading && (
                  <p className="error-text">{errors.actReading}</p>
                )}
              </div>

              <div className="score-input-group">
                <label className="score-label">ACT Writing</label>
                <input type="number" value={formData.previousACTScores.writing}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    previousACTScores: { ...prev.previousACTScores, writing: e.target.value }
                  }))}
                  className="profile-input" placeholder="1-36"
                />
                {errors.actWriting && (
                  <p className="error-text">{errors.actWriting}</p>
                )}
              </div>

              <div className="score-input-group">
                <label className="score-label">ACT Science</label>
                <input type="number" value={formData.previousACTScores.science}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    previousACTScores: { ...prev.previousACTScores, science: e.target.value }
                  }))}
                  className="profile-input" placeholder="1-36"
                />
                {errors.actScience && (
                  <p className="error-text">{errors.actScience}</p>
                )}
              </div>
            </div>
          )}

          <label className="input-label">Have you scheduled an upcoming test? If so, when is it?</label>
          <div className="test-button-group">
            <button
              onClick={() => setFormData(prev => ({ ...prev, hasUpcomingTest: !prev.hasUpcomingTest }))}
              className={`test-toggle-button ${formData.hasUpcomingTest ? 'active' : ''}`}
              type="button"
            >
              I have an upcoming test
            </button>
          </div>

          {formData.hasUpcomingTest && (
            <div className="score-input-group">
              <input
                type="date"
                value={formData.testDate}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  testDate: e.target.value
                }))}
                className="profile-input"
              />
              {errors.testDate && (
                <p className="error-text">{errors.testDate}</p>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );

  const renderStep2 = () => (
    <div className="step-container">
      <Card>
        <label className="input-label">What are the scores you're aiming for?</label>
        <CardContent>
          <div className="goals-input-container">
            <div className="target-scores-grid">
              <div className="score-input-group">
                <label className="input-label">Math</label>
                <input
                  type="number"
                  value={formData.targetScores.math}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    targetScores: { ...prev.targetScores, math: e.target.value }
                  }))}
                  className="profile-input"
                  placeholder="200-800"
                />
                {errors.targetMath && (
                  <p className="error-text">{errors.targetMath}</p>
                )}
              </div>

              <div className="score-input-group">
                <label className="input-label">Reading and Writing</label>
                <input
                  type="number"
                  value={formData.targetScores.readingWriting}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    targetScores: { ...prev.targetScores, readingWriting: e.target.value }
                  }))}
                  className="profile-input"
                  placeholder="200-800"
                />
                {errors.targetReading && (
                  <p className="error-text">{errors.targetReading}</p>
                )}
              </div>
            </div>

            <div className="colleges-section">
              <label className="input-label">
                Which colleges are you interested in?
              </label>
              <textarea
                value={formData.colleges}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  colleges: e.target.value
                }))}
                className="textarea-input"
                placeholder="List the colleges you're interested in..."
              />
              {errors.colleges && (
                <p className="error-text">{errors.colleges}</p>
              )}
            </div>

            <div className="major-section">
              <label className="input-label">
                What major(s) are you considering?
              </label>
              <input
                type="text"
                value={formData.intendedMajor}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  intendedMajor: e.target.value
                }))}
                className="profile-input"
                placeholder="e.g., Computer Science, Biology, Business..."
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderStep3 = () => (
    <div className="step-container">
      <Card>
        {/*
        <CardTitle className="your-title-class">Your Motivation</CardTitle>*/}
        <label className="input-label">What excites you about college? How would a great SAT score help you achieve your dreams?</label>
        <CardContent>
          <textarea
            value={formData.motivation}
            onChange={(e) => setFormData(prev => ({ ...prev, motivation: e.target.value }))}
            className="motivation-textarea"
            placeholder=" I'm excited about...
            It would be awesome if I could...earn $10,000 in scholarships, meet new friends, study abroad to Spain, get a high-paying job..."
            rows={4}
          />
          {errors.motivation && (
            <p className="error-text">{errors.motivation}</p>
          )}
        </CardContent>
      </Card>
      
      <Card>
        {/*<CardTitle className="your-title-class">Academic Interests</CardTitle>*/}
        <label className="input-label">Tell me about your strengths and weaknesses so we can design the right questions and guidance for you!</label>
        <CardContent>
          <textarea
            value={formData.strengthsAndWeaknesses}
            onChange={(e) => setFormData(prev => ({ ...prev, strengthsAndWeaknesses: e.target.value }))}
            className="motivation-textarea"
            placeholder=" I'm in Pre-Calculus now and am good at algebra, but I forgot stuff in Geometry...
             I'm a fast reader, but I miss details sometimes, and I don't know the grammar or punctuation very well..."
            rows={4}
          />
          {errors.strengthsAndWeaknesses && (
            <p className="error-text">{errors.strengthsAndWeaknesses}</p>
          )}
        </CardContent>
      </Card>
    </div>
  );

  const renderStep4 = () => (
    <div className="step-container">
      <Card>
        <CardTitle className="your-title-class">
          <div className="flex-center">
            <BookOpen className="icon" />
            Preferred Feedback Style
          </div>
        </CardTitle>
        <CardContent>
          <button
            className={`feedback-option ${formData.feedbackStyle === 'detailed' ? 'active' : ''}`}
            data-active={formData.feedbackStyle === 'detailed'}
            onClick={() => setFormData(prev => ({ ...prev, feedbackStyle: 'detailed' }))}
            type="button"
          >
            <div className="feedback-title">Detailed Explanations</div>
            <div className="feedback-description">
              I prefer thorough explanations that break down each step of the solution process
            </div>
          </button>

          <button
            className={`feedback-option ${formData.feedbackStyle === 'concise' ? 'active' : ''}`}
            data-active={formData.feedbackStyle === 'concise'}
            onClick={() => setFormData(prev => ({ ...prev, feedbackStyle: 'concise' }))}
            type="button"
          >
            <div className="feedback-title">Quick Tips</div>
            <div className="feedback-description">
              I prefer brief, to-the-point feedback focusing on key concepts
            </div>
          </button>

          {errors.feedbackStyle && (
            <p className="error-text">{errors.feedbackStyle}</p>
          )}
        </CardContent>
      </Card>      
    </div>
  );

  const renderStep5 = () => (
    <div className="step-container">
      <Card>
        <CardTitle className="your-title-class">
          <div className="flex-center">
            <User className="icon" />
            Create Your Account
          </div>
        </CardTitle>
        <CardContent>
          <div className="auth-inputs">
            <div className="input-group">
              <label className="input-label">First Name</label>
              <input
                type="text"
                value={formData.firstName}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  firstName: e.target.value
                }))}
                className="profile-input"
                placeholder="Your first name"
              />
              {errors.firstName && <p className="error-text">{errors.firstName}</p>}
            </div>
            <div className="input-group">
              <label className="input-label">Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  email: e.target.value
                }))}
                className="profile-input"
                placeholder="your.email@example.com"
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>

            <div className="input-group">
              <label className="input-label">Password</label>
              <input
                type="password"
                value={formData.password}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  password: e.target.value
                }))}
                className="profile-input"
                placeholder="Create a password (min. 6 characters)"
              />
              {errors.password && <p className="error-text">{errors.password}</p>}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const handleNextStep = () => {
    console.log(`Step ${step} of ${totalSteps}`);
    if (validateStep(step)) {
      if (step === totalSteps) {
        console.log(`Attempted to handleSubmit()`);
        handleSubmit();
      } else {
        setStep(prev => Math.min(totalSteps, prev + 1));
      }
    }
  };

  const handleSubmit = async () => {
    console.log('1. Handling user registration submission');
    if (validateStep(totalSteps)) {
      try {
        console.log('2. Creating Firebase auth user');
        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(
          auth, formData.email, formData.password
        );
        
        console.log('3. Creating Firestore document');
        const userDocRef = doc(db, 'users', userCredential.user.uid);
        await setDoc(userDocRef, {
          firstName: formData.firstName,
          email: formData.email,
          paidSubscription: false,
          // Profile data
          hasTakenSAT: formData.hasTakenSAT,
          hasTakenACT: formData.hasTakenACT,
          previousSATScores: formData.previousSATScores,
          previousACTScores: formData.previousACTScores,
          targetScores: formData.targetScores,
          colleges: formData.colleges,
          intendedMajor: formData.intendedMajor,
          motivation: formData.motivation,
          strengthsAndWeaknesses: formData.strengthsAndWeaknesses,
          feedbackStyle: formData.feedbackStyle,
          areasForImprovement: formData.areasForImprovement,
          personalInterests: formData.personalInterests,
          // Initialize tracking fields - do I need to add every other field, or will my code add the skill fields as they go through? I think it will.
          grandTotalQuestions: 0,
          completedSATTests: 0,
          totalDaysPracticed: 0,
          numberOfDaysInAStreak: 0,
          currentTestProgress: {
            mathQuestionsAttempted: 0,
            mathCorrectAnswers: 0,
            readingWritingQuestionsAttempted: 0,
            readingWritingCorrectAnswers: 0
          },
          skills: {
            algebra: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            circles: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            descriptive_phrases: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            exponential_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            exponential_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            function_of_sentence: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            geometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            inferences: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            interpreting_graphs: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            linear_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            linear_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            linear_inequality_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            main_idea: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            percent: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            phrasing: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            polynomial_expressions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            probability: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            pronouns: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            punctuation: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            quadratic_equation_word_problems: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            quadratic_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            referencing_data: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            statistics: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            supporting_claims: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            synthesizing_notes: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            systems_of_equations: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            tenses: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            transition_words: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            trigonometry: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            two_passages: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            unit_conversions: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 },
            word_choice: { currentTestQuestionsAttempted: 0, currentTestQuestionsCorrect: 0, overallQuestionsAttempted: 0, overallQuestionsCorrect: 0, easyQuestionsIndex: 0, currentTestEasyQuestionsAttempted: 0, currentTestEasyQuestionsCorrect: 0, mediumQuestionsIndex: 0, currentTestMediumQuestionsAttempted: 0, currentTestMediumQuestionsCorrect: 0, hardQuestionsIndex: 0, currentTestHardQuestionsAttempted: 0, currentTestHardQuestionsCorrect: 0 }
          }
        });

        console.log('4. Registration complete, notifying parent');
        onRegistrationComplete();
      } catch (error: any) {
        console.error('Registration error:', error);
        // Handle specific Firebase auth errors
        let errorMessage = 'Registration failed. Please try again.';
        if (error.code === 'auth/email-already-in-use') {
          errorMessage = 'This email is already registered. Please use a different email or try logging in.';
        } else if (error.code === 'auth/invalid-email') {
          errorMessage = 'Invalid email address format.';
        } else if (error.code === 'auth/weak-password') {
          errorMessage = 'Password is too weak. Please use at least 6 characters.';
        }
        
        setErrors(prev => ({
          ...prev, submit: errorMessage
        }));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
      case 5:
        return renderStep5();
      default:
        return null;
    }
  };

  return (
    <div className="profile-container">
      <div className="progress-bar-container">
        <div 
          className="progress-bar"
          style={{ width: `${(step / totalSteps) * 100}%` }}
        />
      </div>
      
      <div className="header-container">
        <h1 className="profile-title">Create Your Learning Profile</h1>
        <h3 className="step-label">
          Step {step} of {totalSteps}: {
            ['Your Test History', 'Your Goals & Aspirations', 'Your Motivation and Interests', 'Your Learning Style'] //'Personal Interests'
            [step - 1]
          }
        </h3>
      </div>

      {renderCurrentStep()}

      <div className="button-container">
        <button
          onClick={() => setStep(prev => Math.max(1, prev - 1))}
          className={`profile-button back-button`}
          disabled={step === 1}
        >
          Back
        </button>
        <button
          onClick={handleNextStep}
          className="profile-button next-button"
        >
          {step === totalSteps ? 'Submit' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default CreateProfile;


/*<Card>
        <CardTitle className="your-title-class">Areas for Improvement</CardTitle>
        <CardContent>
          <div className="improvement-grid">
            {improvementAreas.map(area => (
              <button
                key={area.id}
                onClick={() => setFormData(prev => ({
                  ...prev,
                  areasForImprovement: prev.areasForImprovement.includes(area.id)
                    ? prev.areasForImprovement.filter(id => id !== area.id)
                    : [...prev.areasForImprovement, area.id]
                }))}
                className={`improvement-button ${
                  formData.areasForImprovement.includes(area.id) ? 'active' : ''
                }`}
                type="button"
              >
                <div className="improvement-icon">{area.icon}</div>
                <div>{area.label}</div>
              </button>
            ))}
          </div>
          {errors.areasForImprovement && (
            <p className="error-text">{errors.areasForImprovement}</p>
          )}
        </CardContent>
      </Card>*/