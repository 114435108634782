import React, { useContext, useState, useEffect } from 'react';
import { PromptContext } from './PromptContext'; // Contains QuestionGenerator's prompt for counting questions
import './SecondPage.css';

const ProgressBar: React.FC = () => {
  const { questionText } = useContext(PromptContext)!; // Access the context to track generated questions
  const [progress, setProgress] = useState(0); // Store progress in percentage
  const totalQuestions = 120; // SAT has 120 total questions

  // This useEffect will track when a new question is generated and update the progress bar
  useEffect(() => {
    if (questionText) {
      // For each new question generated, increase progress
      setProgress((prevProgress) => {
        const newProgress = Math.min(prevProgress + (100 / totalQuestions), 100); // Progress in percentage terms
        return newProgress;
      });
    }
  }, [questionText]);

  return (
    <div style={{ width: '70vw', margin: '20px auto', position: 'relative' }}>
      <div style={{ height: '20px', backgroundColor: '#ddd', borderRadius: '10px', overflow: 'hidden' }}>
        <div
          style={{
            height: '100%',
            width: `${progress}%`,
            backgroundColor: 'green',
            transition: 'width 0.5s ease-in-out',
          }}
        />
      </div>
      <div className = "progress-markers">
        <span>0</span>
        <span>15</span>
        <span>30</span>
        <span>45</span>
        <button className = "cta-button" onClick={() => alert('Estimate Your Score!')}>See Mid-way Score!</button>
        <span>75</span>
        <span>90</span>
        <span>105</span>
        <button className = "cta-button" onClick={() => alert('See Full Score!')}>See Your Full SAT Score!</button>
      </div>
    </div>
  );
};

export default ProgressBar;


//style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}