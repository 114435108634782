import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // Ensure the .js extension is included

const rootElement = document.getElementById('root');
if (rootElement){
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
else{
  console.error('Root element not found in webpage. Check index.tsx');
}



//import reportWebVitals from './reportWebVitals.js'; // Ensure the .js extension is included

//reportWebVitals();   //Add this back in.


//ReactDOM.render(
  //<React.StrictMode>
  //  <App />
  //  <ChatComponent /> {/*using the component*/}
  //</React.StrictMode>,
  //document.getElementById('root')
//); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

