import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext'; // Contains all user data to add firstName, Questions Answered, etc.


const PersonalizedBar: React.FC = () => {
    const { user, loading, error } = useContext(UserContext); //Access Firestore's user data in the "user" object, when they are logged in
    // State for toggling between Practice and Test Mode
    const [isPracticeMode, setIsPracticeMode] = useState(true);

    const toggleMode = () => {
        setIsPracticeMode(!isPracticeMode);
    };

    return (
        <div className="personalized-bar">
            <p>Welcome, {user?.firstName}!</p>
            <p>Days Practiced: {user?.totalDaysPracticed}</p>
            <p>Questions Completed: {user?.grandTotalQuestions}</p>
            
            {/* Toggle Button */}
            <div className="mode-toggle">
                <button
                    className={`toggle-btn ${isPracticeMode ? 'active' : ''}`}
                    onClick={toggleMode}
                >
                    Practice Mode
                </button>
                <button
                    className={`toggle-btn ${!isPracticeMode ? 'active' : ''}`}
                    onClick={toggleMode}
                >
                    Test Mode
                </button>
            </div>
        </div>
    );
};

export default PersonalizedBar;
