// ButtonChoice.tsx
import React, { useState } from 'react';
//import SkillButtons from './SkillButtons';
import SkillButtonsAndQuestionGenerator from './SkillButtonsAndQuestionGenerator';

type MainMenuProps = {
  //define props here
}

const MainMenu: React.FC<MainMenuProps> = () => {
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null); //specifies type string or null

  const subjectList: string[] = ['Reading and Writing', 'Math'];

  const handleSubjectClick = (subject: string) => {
    setSelectedSubject(subject);
  };

  /*These used to be the outer layer below, but they don't matter any more:
  <div className="main-menu"> */
  /*</div> */
  return (
    
      <div className="button-container">
        <h2>Select an SAT Subject to Practice: </h2>
        {subjectList.map((subject) => (
          <button
            key={subject}
            onClick={() => handleSubjectClick(subject)}
            className={`menu-button ${selectedSubject === subject ? 'selected' : ''}`}
          >
            {subject}
          </button>
        ))}
      {selectedSubject && <SkillButtonsAndQuestionGenerator selectedSubject={selectedSubject} />}
      </div>
  );
}; /* the <SkillButtons subjectList={selectedType} /> calls the SkillButtons.js component which calls QuestionGenerator.js */

export default MainMenu;