// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  email: {
    enabled: true, // Check this property
    passwordRequired: true // Optional: Check if password is required
  }
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log('Firebase initialized:', app.name);

// Initialize Firestore - stores user data (progress on SAT, etc.)
export const db = getFirestore(app);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);
console.log('Firebase Auth initialized:', auth); // Log Auth initialization

export default app;
